import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BookNewsFeed() {
  const [bookNews, setBookNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://gnews.io/api/v4/search?q=books&lang=en&max=100&apikey=4395035c9ad2265af9b9bec665c9f8c4`)
      .then((response) => {
        setBookNews([...bookNews, ...response.data.articles]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [page, bookNews]);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setPage(page + 1);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [page]);

  return (
    <div>
      {bookNews.map((article) => (
        <div key={article.title}>
          <img src={article.image} alt={article.title} />
          <h3>{article.title}</h3>
          <p>{article.description}</p>
        </div>
      ))}
      {loading && <p>Loading more articles...</p>}
    </div>
  );
}

export default BookNewsFeed;

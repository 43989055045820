import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./components/Main";
import Signup from "./components/Singup";
import Login from "./components/Login";
import Rajiv from "./Rajiv";
import UserPage from "./Rajiv2";
import UploadPhotoForm from "./Rajiv1";
import Photo from "./photo";
import Content from './Rajiv3';
import Payment from "./Payment";
import BookNewsFeed from "./Newsfeed";
import FileUpload from './Upload'
function App() {
	const user = localStorage.getItem("token");
	
	console.log(user);
	return (
		<Routes>
			{user && <Route path="/rajiv" exact element={<Rajiv />} />}
			<Route path="/upload" exact element={<UserPage />} />
			<Route path="/upload1" exact element={<UploadPhotoForm />} />
			<Route path="/upload12" exact element={<Photo />} />
			<Route path="/content" exact element={<Content />} />
			<Route path="/payment" exact element={<Payment/>} />
			<Route path="/signup" exact element={<Signup />} />
			<Route path="/login" exact element={<Login />} />
			<Route path="/ram" exact element={<Rajiv />} />
			<Route path="/" element={<Navigate replace to="/login" />} />
			<Route path='/newsfeed' element={<BookNewsFeed />} />
			<Route path='/uploading' element={<FileUpload />} />
		</Routes>
	);
}

export default App;

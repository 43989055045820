import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from "./components/Login/helper"
import jwt_decode from "jwt-decode";
const Photo = () => {
	const [photoUrl, setPhotoUrl] = useState(null);
	const getUserFromToken = () => {
		const token = localStorage.getItem("token");
		if (!token) {
		  return null;
		}
		try {
		  const decoded = jwt_decode(token);
		  return decoded;
		} catch (error) {
		  console.error(error);
		  return null;
		}
	  };
  const user = getUserFromToken();
  useEffect(() => {
    const fetchPhoto = async () => {
		try {
			console.log(user._id);
        const response = await axios.get(`${BASE_URL}/api/auth/users/photo/${user._id}`, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'image/png, image/jpeg',
          },
        });
		  console.log(response.data);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = URL.createObjectURL(blob);
        setPhotoUrl(url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPhoto();
  }, [user._id]);

  return (
    <div>
      {photoUrl ? (
        <img src={photoUrl} alt="User photo" />
      ) : (
        <div>No photo found</div>
      )}
    </div>
  );
};

export default Photo;

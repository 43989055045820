import React from 'react';
import UploadPhotoForm from './Rajiv1';

const UserPage = ({ userId }) => {
  return (
    <div>
      <h1>User Page</h1>
      <UploadPhotoForm userId='6413e5c4c5d83cf9ed4e162c' />
    </div>
  );
};

export default UserPage;

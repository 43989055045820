import React, { useState } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const FileUpload = () => {
	const [files, setFiles] = useState([null, null]);
  const [userId, setUserId] = useState('');

  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    try {
		const formData = new FormData();
		const user = getUserFromToken();
	
      formData.append('userId', user._id);
      formData.append('files', files[0]);
		formData.append('files1', files[1]);
		formData.append('files2', files[2]);

      const res = await axios.post('http://localhost:3001/api/upload/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h2>File Upload</h2>
      <input type="file" name='files' onChange={(e) => handleFileChange(e, 0)} />
		  <input type="file" name='files' onChange={(e) => handleFileChange(e, 1)} />
		  <input type="file" name='files' onChange={(e) => handleFileChange(e, 2)} />
     
      <br />
      <button onClick={handleFileUpload}>Upload</button>
    </div>
  );
};

export default FileUpload;

import React, { useState } from 'react';
import { BASE_URL } from "./components/Login/helper"
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
function Rajiv() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
  const getUserFromToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    try {
      const decoded = jwt_decode(token);
		console.log(decoded);
      return decoded;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
	const handlelogout = () => { 
		localStorage.removeItem('token');
		navigate('/signup')
	}

  // Function to handle form submission
	  const handleSubmit = async () => {
		const user = getUserFromToken();
	const response = await fetch(`${BASE_URL}/api/auth/update-password`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
       
		},
		body: JSON.stringify({
			user,
		  currentPassword,
		  newPassword,
		  confirmPassword
		})
	  });
	  const data = await response.json();
	  if (response.ok) {
		alert(data.message);
	  } else {
		setError(data.error);
	  }
	  
  };

  return (
	  <form onSubmit={handleSubmit}>
		  <button onClick={handlelogout}>logout</button>
      <label>
        Current Password:
        <input
          type="password"
          value={currentPassword}
          onChange={(event) => setCurrentPassword(event.target.value)}
        />
      </label>
      <br />
      <label>
        New Password:
        <input
          type="password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
      </label>
      <br />
      <label>
        Confirm Password:
        <input
          type="password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
      </label>
      <br />
      <button type="submit">Update Password</button>
      {error && <div>{error}</div>}
    </form>
  );
}

export default Rajiv;

import React, { useState } from "react";
import axios from "axios";
import { useForm } from 'react-hook-form';

import useRazorpay from 'react-razorpay';

const Payment = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [words, setWords] = useState("");
	const [totalMoney, setTotalMoney] = useState("");
	const { register, handleSubmit, errors, watch } = useForm();
	const [paymentAmount, setPaymentAmount] = useState(0);
	const [amount, setAmount] = useState(0);
	const Razorpay = useRazorpay();
	const mobile=false
  const options = [
    { id: 1, label: "Option 1", value: 0.8 },
    { id: 2, label: "Option 2", value: 0.4 },
    { id: 3, label: "Option 3", value: 0.8 },
    { id: 4, label: "Option 4", value: 0.8 },
  ];
	const prices = {
	  
    option1: 100,
    option2: 200,
    option3: 300,
    option4: 300,
  };
  
  const handleOptionChange = (option) => {
	  setSelectedOption(option);
	  const price = prices[selectedOption];
	  setPaymentAmount(price * 100);
	  console.log(price)
  };

  const handleWordsChange = (event) => {
    setWords(event.target.value);
  };
	
  const calculateTotalMoney = () => {
    const costPerWord = selectedOption.value;
    const wordsCount = parseInt(words);
    const total = costPerWord * wordsCount;
    setTotalMoney(total.toFixed(2));
    setPaymentAmount(total);
  };
  function handleRadioChange(event) {
    if (event.target.value === 'Substantive Editing') {
      setAmount(0.8);
    } else if (event.target.value === 'Copyediting') {
      setAmount(1.2);
    } else if (event.target.value === 'Proofreading') {
      setAmount(0.5);
    } // add more cases for other radio button options
	}
	const onSubmit = async (data, e) => { 
		console.log(data)
		console.log(data.Wordsbook);
		console.log(amount);
		const orderAmount = amount * parseFloat(data.Wordsbook);
		console.log(orderAmount);
		const r = parseInt(orderAmount);
		
		try {
			const response = await axios.post("http://localhost:3001/api/payment/api/payment", {
			  amount: r,
			});
			const options = {
			  key: 'rzp_test_Jtpvppi2NrnCCK',
			  amount: response.data.amount,
			  currency: response.data.currency,
			  name: "Example App",
			  description: "Example payment",
			  order_id: response.data.order_id,
			  handler: function (response) {
				alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
			  },
			  prefill: {
				name: "John Doe",
				email: "johndoe@example.com",
			  },
			};
			const razorpayInstance = new Razorpay(options);
			razorpayInstance.open();
		  } catch (error) {
			console.log(error);
			alert("Error processing payment");
		  }
	}
	const handlePayment = async () => {
	  
    
  };

  return (
	  <div>
		  <form onSubmit={handleSubmit} >
		  <label htmlFor="field-rain" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Substantive Editing"
					  id="field-rain" 
					  onChange={handleRadioChange}
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Substantive Editing
                </label><br/>
                <label htmlFor="field-wind" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Copyediting"
					  id="field-wind"
					  onChange={handleRadioChange}
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Copyediting
                </label><br/>
                <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Proofreading"
					  id="field-sun"
					  onChange={handleRadioChange}
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Proofreading
					  </label><br />
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Beta-reading"
					  id="field-sun"
					  onChange={handleRadioChange}
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Beta-reading
					  </label><br />
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Editorial Evaluation"
					  id="field-sun"
					  onChange={handleRadioChange}
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Editorial Evaluation
					  </label><br/>
					  <label htmlFor="field-sun" style={{marginLeft:mobile?'16px':'82px',fontSize:mobile?'12px':'18px',lineHeight:mobile?'16px':"28px",marginTop:mobile?'2px':'8px'}}>
                    <input
                        {...register("EditingType")}
                        type="radio"
                        value="Narrative Assessment"
							id="field-sun"
							style={{width:mobile?"8px":'16px',
							height:mobile?"8px":'16px',marginRight:mobile?'6px':'12px'}}
                    />
                    Narrative Assessment
			  </label><br />
			  <input placeholder='Write here'
							id="Wordsbook"
							name="Wordsbook"
							    
							margin="dense"
							{...register('Wordsbook')}
							
							inputProps={{ style: { padding:mobile?'8px 8px':'14.5px 16px' } }}
			  />
			   <button type="submit" onClick={handleSubmit(onSubmit)}>C</button>
		  </form>
      <h1>Select an option:</h1>
      {options.map((option) => (
        <div key={option.id}>
          <input
            type="radio"
            id={option.id}
            name="options"
            value={option.label}
            checked={selectedOption && selectedOption.label === option.label}
            onChange={() => handleOptionChange(option)}
          />
          <label htmlFor={option.id}>{option.label}</label>
        </div>
      ))}
      <h1>Enter the number of words:</h1>
      <input type="number" value={words} onChange={handleWordsChange} />
      <button onClick={calculateTotalMoney}>Calculate total</button>
      {totalMoney && <h1>Total money: {totalMoney}</h1>}
      <button onClick={handlePayment} disabled={!totalMoney}>
        Pay with Razorpay
      </button>
    </div>
  );
};

export default Payment;

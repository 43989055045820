import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from "./components/Login/helper"
import jwt_decode from "jwt-decode";
const UploadPhotoForm = ({ userId }) => {
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState(null);
  const getUserFromToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    try {
      const decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
	const user = getUserFromToken();
    const formData = new FormData();
    formData.append('photo', photo);
	formData.append('userId', user._id);
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/users/photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
		  'Authorization': `Bearer ${localStorage.getItem('token')}`,
		  },
      });

      console.log(response.data);
    } catch (error) {
      console.error(error);
      setError('Something went wrong');
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <div>{error}</div>}
      <input type="file" name="photo" onChange={handlePhotoChange} />
      <button type="submit">Upload</button>
    </form>
  );
};

export default UploadPhotoForm;
